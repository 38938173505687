import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
import { First } from '@kastle/ui/src/molecules/Showcases/Desktop';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "application-web-et-site-internet"
    }}>{`Application web et site internet`}</h1>
    <First mdxType="First" />
    <h2 {...{
      "id": "notre-agence-de-développement-web-à-vos-services"
    }}>{`Notre agence de développement web à vos services`}</h2>
    <p>{`Posséder un site Web est primordiale pour une entreprise aujourd’hui. Cependant, il ne suffit plus de simplement afficher sur le Web sans se démarquer. Votre site Web est le fondement de votre entreprise numérique à l’ère du digital.`}</p>
    <p>{`Le site internet doit représenter au mieux votre entreprise et mettre en valeur la personnalité de votre marque. Si vous souhaitez avoir une très bonne image de votre marque et profiter des nombreuses opportunités que vous offre internet et le digital, vous devez vous démarquer`}</p>
    <p>{`Évidemment, cela ne peut être réalisé que grâce à des services de développement Web personnalisés, sur mesure et expérimentés.`}</p>
    <h2 {...{
      "id": "quest-ce-que-le-développement-web-"
    }}>{`Qu’est-ce que le développement Web ?`}</h2>
    <p>{`La création de sites Web ou de pages Web s’éffectue des langages techniques (JavaScript, PHP, Ruby, Java, HTML, CSS, etc…)
Les programmes sont ensuite exécutés par des ordinateurs. Selon les besoins techniques, le développement peut inclure uniquement du texte et des médias mais il peut être interactif en affichant des données évolutives (telles que des animations sur votre application ou encore paniers d’achat pour les sites de commerce électronique en ligne).`}</p>
    <h2 {...{
      "id": "nos-services-de-créations-et-de-développement-dapplications-web"
    }}>{`Nos services de créations et de développement d’applications Web`}</h2>
    <p>{`Nous sommes une agence spécialisée dans le développement web et reconnue pour la qualité de son travail ainsi que son expertise et son savoir-faire.
Notre expérience nous permet de répondre aux demandes les plus complexes telles que l’intégration graphique la plus simple ou encore des applications métiers complexes. Créer une plateforme digitale est notre passion, nous disposons de nombreux CMS, solutions e-commerce et frameworks pour applications web.`}</p>
    <p>{`Nos experts peuvent effectuer de nombreuses tâches à différents niveaux de difficultés:`}</p>
    <ul>
      <li parentName="ul">{`Développement personnalisé`}</li>
      <li parentName="ul">{`Intégrer le site Web avec un CMS`}</li>
      <li parentName="ul">{`Créer une application Web mobile`}</li>
      <li parentName="ul">{`Implémenter des applications métiers pour une entreprise et des collectivités`}</li>
      <li parentName="ul">{`Un intranet, CRM mais aussi un ERM`}</li>
      <li parentName="ul">{`Une application personnalisé`}</li>
      <li parentName="ul">{`Effectuer des applications Web d’entreprise`}</li>
      <li parentName="ul">{`Développement back-end et front-end`}</li>
      <li parentName="ul">{`Intégration d’API tierce etc..`}</li>
      <li parentName="ul">{`Avoir un site scalable pour des millions d’utilisateurs`}</li>
    </ul>
    <p><inlineCode parentName="p">{`Notre mission est d'aider nos clients à réaliser avec succès leurs produits digitaux !`}</inlineCode></p>
    <ButtonInternal to="/contact" mdxType="ButtonInternal">
    Je demande un devis gratuit
    </ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      